.input-range__label--min{
  display: none;
}

.input-range__label--max{
  display: none;
}

.input-range__track--active{
  background-color: black !important;
}

.input-range__slider {
  background: black !important;
  border: 1px solid black !important;
  border-radius: 30% !important;
}

.input-range__label {
  color: black !important;
}

.input-range {
  margin-left: 12px !important;
  width: 90% !important;
}

.input-range__label--value {
  top: -2rem !important;
}
