.css-wmy1p7-ReactDropdownSelect{
  border: "none"
}

css-zt18l1-InputComponent, .css-1c8t16q-InputComponent,
.css-zt18l1-InputComponent::placeholder, .css-jxl13i-InputComponent,
.css-jxl13i-InputComponent::placeholder, .css-1c8t16q-InputComponent::placeholder {
    font-size: 15px;
    color: white;
    font-weight:500;
    text-align:center !important;
}


::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}

.css-1963x02-ItemComponent{
    background-color: gray;
}

.css-f84s83-OptionComponent{
    border: 1px solid white;
    max-height:25px;
}

.css-1963x02-ItemComponent{
    background-color: white;
    color: gray;
    border-bottom: 1px solid gray !important;
}

.rdrCalendarWrapper{
    position: absolute !important;
    z-index: 100000 !important;
    margin-left:-175px;
}

/*.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
    display: none !important;
}

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: white !important;
    font-family: "Poppins", sans-serif !important;
}

.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
    color: white !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: none !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: white !important;
    font-family: "Poppins", sans-serif !important;
    text-align: center !important;
}*/

.MuiTextField-root>label{
    display: none important;
}